<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>社区管理</el-breadcrumb-item>
            <el-breadcrumb-item>板块管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="addSec('new','')" size="small">添加</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                        <el-button size="mini" type="primary" icon="el-icon-edit" @click="addSec('bj',scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除</el-button>
                        <el-button size="mini" type="warning" icon="el-icon-edit" @click="addTwoSec(scope.row)">添加二级板块</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form" class="hdtc" label-width="170px">
                <el-form-item label="板块">
                    <el-input style="float: left;" v-model="form.name" autocomplete="off" placeholder="请输入板块名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <upload :src.sync="form.pic"></upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;form = {}">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import upload from '../components/upload'
    export default {
        components: {
            upload
        },
        inject: ['reload'],
        data() {
            return {
                list: [],
                dialogFormVisible: false,
                form: {
                    name: '',
                    pic: ''
                },
                type: '',
                item: '',
                title: '添加板块'
            }
        },
        mounted() {
            this.getdata();
        },
        methods: {
            getdata() {
                this.axios.get('/bbs/get_gu_bbs_forum?forum_id=' + 0)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.list = res.data.result;
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            addSec(type, item) {
                this.dialogFormVisible = true;
                this.type = type;
                this.item = item;
                if (type != 'new') {
                    this.title = '编辑板块';
                    this.form.name = item.name;
                    this.form.pic = item.pic;
                } else {
                    this.title = '添加板块';
                    this.form.name = '';
                    this.form.pic = '';
                }
            },
            del(row, index) {
                this.$confirm('此操作将永久删除该板块, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/bbs/delete_gu_bbs_forum", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            addTwoSec(row) {
                this.$router.push({ path: '/addSection/addTwoSection', query: { id: row.id, name: row.name } });
            },
            sub() {
                let url = '';
                let str = '';
                this.form.forum_id = 0;
                if (this.type == 'new') {
                    url = "/bbs/insert_gu_bbs_forum";
                    str = '添加成功'
                } else {
                    url = "/bbs/update_gu_bbs_forum";
                    str = "修改成功";
                    this.form.id = this.item.id;
                }
                if (this.form.pic == '') {
                    this.$message({
                        message: '请上传图片',
                        type: 'warning'
                    });
                    return
                } else if (this.form.name == '') {
                    this.$message({
                        message: '请填写板块',
                        type: 'warning'
                    });
                    return
                } else {
                    this.axios.post(url, this.qs.stringify(this.form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.dialogFormVisible = false;
                            this.form = {};
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }

            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .hdtc {
        width: 80%;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }
</style>